.modal {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;

}

.auth {
    margin: 0 auto;
    background-color: #eee;
    border-radius: 10px;
    padding-top: 10px;
}

.icon {
    position: fixed;
    top: 20px;
    right: 10px;
    background-color: dodgerblue;
    padding: 5px;
    color: white;
    border-radius: 10px;
    cursor: pointer;
}
