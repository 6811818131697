.wrapper {
    display: flex;
}

.wrapper + .wrapper {
    margin-top: 10px;
    border-top: 1px solid #ddd;
    padding-top: 10px;
}

.left {
    margin-right: 20px;
}

.right {
    flex: 1;
    min-width: 0;
}

.bottom {
    margin-top: 5px;
    display: flex;

    align-items: center;
    justify-content: space-between;
}

.photo {
    border-radius: 20px;
    overflow: hidden;
}

.photo img {
    width: 128px;
}

.name {
    font-size: 1.2em;
    font-weight: 500;
}

.name a {
    color: rgb(30, 130, 200)
}

.description {
    margin-top: 5px;
}

.price {
    font-size: 1.2em;
    text-align: right;
    color: green;
}

.url {
    display: inline-block;
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media only screen and (min-width: 300px) and (max-width: 414px) {
    .left {
        margin-right: 10px;
    }
    .photo img {
        width: 64px;
    }
    .name {
        font-size: 1em;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
    .description {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
    .price {
        font-size: 1.1em;
    }
}
