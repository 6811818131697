.wrapper {
    width: 100%;
    max-width: 600px;
    margin: 10px auto 0;
    background-color: #eee;
    padding: 20px;
    box-sizing: border-box;
    border-radius: 10px;
}


@media only screen and (min-width: 300px) and (max-width: 414px) {
    .wrapper {
        margin-top: 0;
        padding: 10px;
    }
}
